const contractorSchema = {
    "$id": "https://example.com/person.schema.json",
    "$schema": "https://json-schema.org/draft/2020-12/schema",
    "title": "Contractor",
    "type": "object",
    "required": ["name", "company", "title", "alias",
        "yoe", "skills",
        "rate", "hours",
        "email"],
    "properties": {
        "name": {
            "type": "string",
            "minLength": 5,
            "maxLength": 50,
        },
        "company": {
            "type": "string",
            "enum": ["Google", "Amazon", "Meta", "Microsoft", "Apple"]
        },
        "level": {
            "type": "string",
            "enum": ["Senior", "Principal", "Staff", "Senior Staff", "3", "4", "5", "6", "7", "8"]
        },
        "title": {
            "type": "string",
            "enum": ["Software Engineer", "Data scientist", "Program Manager", "Project Manager", "UX Designer"]
        },
        "alias": {
            "type": "string",
            "minLength": 2,
            "maxLength": 30,
            "description": "Only used for verification purpose, won't be displayed publicly."
        },
        "yoe": {
            "type": "integer",
            "minimum": 0,
            "maximum": 100
        },
        "skills": {
            "type": "string",
            "minLength": 2,
            "maxLength": 300,
            "pattern": "^[0-9a-zA-Z ]+(,[0-9a-zA-Z ]+)*$"
        },
        "email": {
            "type": "string",
            "format": "email",
            "maxLength": 128
        },
        "rate": {
            "type": "integer",
            "minimum": 1,
            "maximum": 10000
        },
        "hours": {
            "type": "integer",
            "minimum": 1,
            "maximum": 168
        },
        "link": {
            "description": "LinkedIn page or personal website",
            "type": ["string", "null"],
            "format": "uri"
        },
    },
    "additionalProperties": false
};

const clientSchema = {
    "$id": "https://example.com/person.schema.json",
    "$schema": "https://json-schema.org/draft/2020-12/schema",
    "title": "Client",
    "type": "object",
    "required": ["name", "company", "email", "description", "hourlyBudget", "totalBudget"],
    "properties": {
        "name": {
            "type": "string",
            "minLength": 5,
            "maxLength": 50,
        },
        "company": {
            "type": "string",
            "minLength": 5,
            "maxLength": 50,
        },
        "hourlyBudget": {
            "type": "integer",
            "minimum": 0,
            "maximum": 100000000,
        },
        "totalBudget": {
            "type": "integer",
            "minimum": 0,
            "maximum": 100000000
        },
        "email": {
            "type": "string",
            "format": "email",
            "maxLength": 128
        },
        "description": {
            "type": "string",
            "minLength": 10,
            "maxLength": 3000,
        }
    },
    "additionalProperties": false
};

module.exports = { contractorSchema, clientSchema };